import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"
import _imports_0 from '@/assets/logo_ovea.png'


const _hoisted_1 = {
  class: "navbar",
  role: "navigation",
  "aria-label": "main navigation"
}
const _hoisted_2 = { class: "navbar-brand" }
const _hoisted_3 = ["title"]
const _hoisted_4 = {
  id: "navBarMenu",
  class: "navbar-menu"
}
const _hoisted_5 = { class: "navbar-start" }
const _hoisted_6 = { class: "navbar-end" }

import { computed } from 'vue';

import { useStore } from '@/store';


export default /*@__PURE__*/_defineComponent({
  ...{ name: 'NavBar' },
  __name: 'NavBar',
  setup(__props) {



const store = useStore();

const reloadConfirm = () => {
  // TODO: utiliser une fenêtre modale
  // eslint-disable-next-line no-alert, no-restricted-globals
  if (confirm("Une nouvelle version de l'application est disponible.\nRecharger la page ?")) {
    window.location.reload();
  }
};

const version = computed(() => store.getters['version/version']);
const newVersion = computed(() => store.getters['version/newVersion']);

return (_ctx: any,_cache: any) => {
  const _component_RouterLink = _resolveComponent("RouterLink")!

  return (_openBlock(), _createElementBlock("nav", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("span", {
        class: "navbar-item",
        title: `v${version.value}`
      }, _cache[0] || (_cache[0] = [
        _createElementVNode("img", {
          src: _imports_0,
          class: "logo"
        }, null, -1),
        _createElementVNode("h5", null, "Éligibilité", -1)
      ]), 8, _hoisted_3),
      _cache[1] || (_cache[1] = _createElementVNode("a", {
        role: "button",
        class: "navbar-burger",
        "aria-label": "menu",
        "aria-expanded": "false",
        "data-target": "navBarMenu"
      }, [
        _createElementVNode("span", { "aria-hidden": "true" }),
        _createElementVNode("span", { "aria-hidden": "true" }),
        _createElementVNode("span", { "aria-hidden": "true" })
      ], -1))
    ]),
    _createElementVNode("div", _hoisted_4, [
      _createElementVNode("div", _hoisted_5, [
        _createVNode(_component_RouterLink, {
          class: "navbar-item",
          to: { name: 'Recherche' }
        }, {
          default: _withCtx(() => _cache[2] || (_cache[2] = [
            _createTextVNode("Requêtes")
          ])),
          _: 1
        }),
        _createVNode(_component_RouterLink, {
          to: { name: 'Historique' },
          class: "navbar-item"
        }, {
          default: _withCtx(() => _cache[3] || (_cache[3] = [
            _createTextVNode("Historique Requêtes")
          ])),
          _: 1
        })
      ]),
      _createElementVNode("div", _hoisted_6, [
        (newVersion.value)
          ? (_openBlock(), _createElementBlock("a", {
              key: 0,
              class: "has-background-warning navbar-item",
              onClick: reloadConfirm
            }, "Nouvelle version"))
          : _createCommentVNode("", true),
        _createVNode(_component_RouterLink, {
          to: { name: 'Compte' },
          class: "navbar-item"
        }, {
          default: _withCtx(() => _cache[4] || (_cache[4] = [
            _createTextVNode("Compte")
          ])),
          _: 1
        }),
        _createVNode(_component_RouterLink, {
          to: { name: 'Logout' },
          class: "navbar-item"
        }, {
          default: _withCtx(() => _cache[5] || (_cache[5] = [
            _createTextVNode("Logout")
          ])),
          _: 1
        })
      ])
    ])
  ]))
}
}

})